<template>
  <ValidationProvider
    v-if="field.is_shown"
    v-slot="{ errors, valid }"
    :vid="vid"
    :name="getInputLabel($attrs)"
    :rules="getValidationRules(field)"
  >
    <b-field
      v-bind="$attrs"
      :type="{ 'is-danger': errors[0], 'is-success': valid }"
      :message="errors"
      :label="getFieldTag('checkbox', field) ||
      getFieldTag('html_content', field) ? '' : getInputLabel($attrs)"
    >
      <!--
      <template v-slot:message>
        <div v-if="errors.length > 0">
        <template v-for="error in errors"> {{ error }} &nbsp;</template>
        </div>
        <div v-else-if="!getFieldTag('checkbox', field)" v-html="field.helpText"></div>
      </template>
      -->
      <b-input v-if="getFieldTag('input', field)" v-model="innerValue" v-bind="$attrs" />

      <b-input v-if="getFieldTag('textarea', field)"
               type="textarea"
               v-model="innerValue" v-bind="$attrs" />

      <b-input
        v-if="getFieldTag('password', field)"
        v-model="innerValue"
        v-bind="$attrs"
        type="password"
      />

      <b-checkbox :class="[field.name.toUpperCase() != 'KVKK ONAY' ? '':'kvkk-checkbox']"
                  v-if="getFieldTag('checkbox', field)" v-model="innerValue">
        <!-- eslint-disable-next-line -->
        <span v-if="field.name.toUpperCase() != 'KVKK ONAY'" v-html="field.helpText" />
        <span else v-html="$t('kvkk_onay')" />
      </b-checkbox>

      <b-select v-if="getFieldTag('select', field)" v-model="innerValue" v-bind="$attrs">
        <option
          v-for="option in getSelectBoxOptions(field)"
          :key="option"
          :value="option"
        >
          {{ option }}
        </option>
      </b-select>

      <div class="field-content"
           v-if="getFieldTag('html_content', field)"
           v-html="field.content"></div>
    </b-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import _ from 'lodash';

export default {
  components: {
    ValidationProvider,
  },
  props: {
    // eslint-disable-next-line
    field: {
      type: Object,
    },
    // eslint-disable-next-line
    vid: {
      type: String,
    },
    // eslint-disable-next-line
    value: {
      type: null,
    },
  },
  data() {
    return {
      innerValue: '',
      validationRules: {
        name: 'alpha_spaces',
        last_name: 'alpha_spaces',
        city: 'alpha_spaces',
        email: 'email',
        phone: 'numeric|len:10',
        tcid: 'numeric|len:11',
        tcidf: 'numeric|len:11',
        birth_year: 'numeric|len:4',
        number: 'numeric',
        checkbox: null,
        event_password: null,
        email_permission: null,
        selectbox: null,
        short_text: null,
        long_text: null,
        country: null,
        html_content: null,
      },
      fieldTypeMap: {
        input: [
          'name',
          'last_name',
          'email',
          'city',
          'phone',
          'short_text',
          'number',
          'birth_year',
          'tcid',
          'tcidf',
        ],
        textarea: ['long_text'],
        select: ['country', 'selectbox', 'select_box'],
        checkbox: ['checkbox'],
        password: ['event_password'],
        html_content: ['html_content'],
      },
    };
  },
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit('input', newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    },
  },
  created() {
    const self = this;

    if (this.value) {
      this.innerValue = this.value;
    }
    if (this.field.name.toUpperCase() === 'KVKK ONAY') {
      setTimeout(() => {
        if (document.querySelector('.kvkk-checkbox')) {
          document.querySelector('.kvkk-checkbox').addEventListener('click', (e) => {
            e.preventDefault();
            self.$parent.$parent.$parent.isImageModalActive = true;
            setTimeout(() => {
              if (window.document.documentMode) {
                document.querySelector('.modal-card-body').addEventListener('scroll', (event) => {
                  if ((event.srcElement.scrollTop + event.srcElement.clientHeight + 1)
                    >= event.srcElement.scrollHeight) {
                    const tempButton = event.srcElement.parentElement.querySelector('.button');
                    tempButton.disabled = false;
                    tempButton.addEventListener('click', () => {
                      self.kvkkOnay();
                    });
                  }
                });
              } else {
                document.querySelector('.modal-card-body').addEventListener('scroll', (event) => {
                  if ((event.srcElement.scrollTop + event.srcElement.clientHeight + 1)
                    >= event.srcElement.scrollHeight) {
                    const tempButton = event.srcElement.parentElement.querySelector('.button');
                    tempButton.disabled = false;
                    tempButton.addEventListener('click', () => {
                      self.kvkkOnay();
                    });
                  }
                });
              }
            }, 500);
          });
        }
      }, 500);
    }
  },
  methods: {
    kvkkOnay() {
      this.$parent.$parent.$parent.isImageModalActive = false;
      this.innerValue = true;
    },
    getValidationRules(formItem) {
      const ruleObject = {};
      if (formItem.required === true) {
        ruleObject.required = true;
      } else {
        ruleObject.required = false;
      }

      switch (formItem.type) {
        case 'name':
          ruleObject.alpha_spaces = true;
          break;
        case 'last_name':
          ruleObject.alpha_spaces = true;
          break;
        case 'email':
          ruleObject.email = true;
          break;
        case 'tcid':
          ruleObject.numeric = true;
          ruleObject.len = 11;
          ruleObject.tcid = true;
          break;
        case 'tcidf':
          ruleObject.numeric = true;
          ruleObject.len = 11;
          break;
        case 'birth_year':
          ruleObject.numeric = true;
          ruleObject.len = 4;
          break;
        case 'number':
          ruleObject.numeric = true;
          break;
        case 'checkbox':
          if (ruleObject.required === true) {
            ruleObject.required = { allowFalse: false };
          }
          break;
        default:
          break;
      }

      return ruleObject;
      /*
      return `
        ${formItem.required ? 'required' : ''}
        ${formItem.required && this.validationRules[formItem.fieldType] ? '|' : ''}
        ${this.validationRules[formItem.fieldType] ? this.validationRules[formItem.fieldType] : ''}
        `.replace(/\s/g, ''); // remove the spaces caused by multine.
      */
    },
    getFieldTag(htmlTag, formItem) {
      return this.fieldTypeMap[htmlTag].includes(formItem.type);
    },
    getSelectBoxOptions(formItem) {
      return formItem.options;
    },
    getInputLabel(attrs) {
      const word = attrs.label || attrs.name;

      const result = this.$t(_.replace(word, / /g, '_').toLowerCase());

      if (result === _.replace(word, / /g, '_').toLowerCase()) {
        return word;
      }

      return result;
    },
  },
};
</script>

<style>
.b-checkbox.checkbox {
  align-items: flex-start;
}
.b-checkbox.checkbox .control-label p {
  user-select: text;
}
/* custom scroll deleted :( Sad
.modal-card-body::-webkit-scrollbar {
  width: 5px;
}

.modal-card-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.modal-card-body::-webkit-scrollbar-thumb {
  background: #555;
}

.modal-card-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
*/
</style>
