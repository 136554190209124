var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isReady)?_c('div',{staticClass:"landing",style:(("background-image: url('" + (_vm.$root.event && _vm.$root.event.pageSettings
        ? _vm.$root.event.pageSettings.bgImgUrl
        : _vm.defaultBackgroundImg) + "')"))},[_c('b-modal',{scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(_vm._s(_vm.$t('kvkk_header')))]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":props.close}})]),_c('section',{staticClass:"modal-card-body"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$root.event.regForm
            .find(function (v) { return v.name.toUpperCase() === 'KVKK ONAY'; }).helpText)}})]),_c('footer',{staticClass:"modal-card-foot"},[_c('div',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(_vm.$t('read_kvkk_to_end'))+" ")]),_c('b-button',{staticClass:"mr-5",staticStyle:{"justify-self":"flex-end","margin":"auto"},attrs:{"disabled":"true","type":"is-primary","label":_vm.$t('approve')}})],1)])]}}],null,false,567981663),model:{value:(_vm.isImageModalActive),callback:function ($$v) {_vm.isImageModalActive=$$v},expression:"isImageModalActive"}}),(_vm.$root.event)?_c('div',{staticClass:"container is-max-widescreen"},[_c('div',{staticClass:"container-display"},[_c('div',{staticClass:"columns is-centered",staticStyle:{"width":"100%"}},[(
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
            )?_c('div',{staticClass:"column is-half-tablet is-three-fifths-widescreen px-5"},[(
                _vm.$root.event.pageSettings && _vm.$root.event.pageSettings.logoUrl
              )?_c('img',{attrs:{"src":_vm.$root.event.pageSettings.logoUrl}}):_vm._e(),_c('div',{staticClass:"mt-1 mb-3",domProps:{"innerHTML":_vm._s(_vm.$root.event.pageSettings.landingContent)}})]):_vm._e(),_c('div',{staticClass:"column",class:[
              _vm.$root.event.pageSettings &&
              (_vm.$root.event.pageSettings.logoUrl ||
                _vm.$root.event.pageSettings.landingContent)
                ? ' is-half-tablet is-two-fifths-widescreen'
                : 'is-full-tablet is-three-fifths-widescreen no-event-content' ]},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('h3',{staticClass:"is-size-5 has-text-weight-bold title"},[_vm._v(" "+_vm._s(_vm.$t('registration'))+" ")]),_c('FormBase')],1)])])])])]):_c('div',{staticClass:"no-event-fount"},[_vm._v(_vm._s(_vm.$t('no_event_found')))])],1):_vm._e(),_c('b-loading',{attrs:{"is-full-page":true},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }