import Vue from 'vue';
import Buefy from 'buefy';
import '@firebase/polyfill';
import '@mdi/font/css/materialdesignicons.css';
import '@/styles/main.scss';
import { i18n } from '@/utils/i18n-setup';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import './vee-validate';

Vue.config.productionTip = false;
Vue.use(Buefy);

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
