<template>
  <div>
    <div
      v-if="isReady"
      :style="`background-image: url('${
        $root.event && $root.event.pageSettings
          ? $root.event.pageSettings.bgImgUrl
          : defaultBackgroundImg
      }')`"
      class="landing"
    >
      <b-modal v-model="isImageModalActive">
        <template #default="props">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">{{ $t('kvkk_header') }}</p>
              <button
                  type="button"
                  class="delete"
                  @click="props.close"/>
            </header>
            <section class="modal-card-body">
              <div v-html="$root.event.regForm
              .find((v) => v.name.toUpperCase() === 'KVKK ONAY').helpText"></div>
            </section>
            <footer class="modal-card-foot">
              <div class="ml-3">
                {{ $t('read_kvkk_to_end') }}
              </div>
              <b-button
                  disabled="true"
                  class="mr-5"
                  style="justify-self: flex-end; margin: auto;"
                  type="is-primary"
                  :label="$t('approve')" />
            </footer>
          </div>
        </template>
      </b-modal>

      <div v-if="$root.event" class="container is-max-widescreen">
        <div class="container-display">
          <div class="columns is-centered" style="width: 100%">
            <!-- eslint-disable max-len -->
            <div
              v-if="
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
              "
              class="column is-half-tablet is-three-fifths-widescreen px-5"
            >
              <img
                v-if="
                  $root.event.pageSettings && $root.event.pageSettings.logoUrl
                "
                :src="$root.event.pageSettings.logoUrl"
              />
              <div
                class="mt-1 mb-3"
                v-html="$root.event.pageSettings.landingContent"
              />
            </div>

            <div
              :class="[
                $root.event.pageSettings &&
                ($root.event.pageSettings.logoUrl ||
                  $root.event.pageSettings.landingContent)
                  ? ' is-half-tablet is-two-fifths-widescreen'
                  : 'is-full-tablet is-three-fifths-widescreen no-event-content',
              ]"
              class="column"
            >
              <!--
              <h1 class="title has-text-centered">
                {{ $root.event.title }}
              </h1>
              -->

              <div class="card">
                <div class="card-content">
                  <h3 class="is-size-5 has-text-weight-bold title">
                    {{ $t('registration') }}
                  </h3>
                  <FormBase />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-event-fount">{{ $t('no_event_found') }}</div>
    </div>
    <b-loading :is-full-page="true" v-model="isLoading"></b-loading>
  </div>
</template>

<script>
// @ is an alias to /src
import FormBase from '@/components/regForm/FormBase.vue';
import httpClient from '@/api/client';
import { loadLanguageAsync } from '@/utils/i18n-setup';
import { localize } from 'vee-validate';

export default {
  name: 'Home',
  components: {
    FormBase,
  },
  data() {
    return {
      isKvkkReaded: false,
      isImageModalActive: false,
      isReady: false,
      isLoading: true,
      selectedLang: 'en',
      defaultBackgroundImg: '',
    };
  },
  async created() {
    try {
      const res = await httpClient.get(
        `/event/${this.$router.currentRoute.params.slug}`,
      );
      /* eslint-disable prefer-destructuring */
      this.$root.event = res.data.event;

      document.title = res.data.event.title;
      this.selectedLang = res.data.event.interfaceLang;
      this.updateSelectedLang();
      this.isReady = true;
      this.isLoading = false;
    } catch (error) {
      console.log('=>', error);
      this.isReady = true;
      this.isLoading = false;
    }
  },
  watch: {
    event(val) {
      console.log(val);
    },
  },
  mounted() {},
  methods: {
    updateSelectedLang() {
      loadLanguageAsync(this.selectedLang);
      localize(this.selectedLang);
    },
  },
};
</script>
<style>
html {
  background-color: #f5f5f5;
}

@media (min-width:960px) {
  .columns {
    margin-top: 128px;
  }
}

@media (max-width:960px) {
  .columns {
    margin-top: 48px;
  }
}

.no-event-content {
  max-width: 830px !important;
  margin: auto;
}

.is-size-5 {
  font-size: 1.25rem !important;
}

.label,
.control-label {
  font-size: 0.875rem !important;
}

.field-content p {
  font-size: 0.875rem !important;
}

.field-content h6 {
  font-size: 1rem !important;
}

.field-content h5 {
  font-size: 1.25rem !important;
}

.field-content h4 {
  font-size: 1.5rem !important;
}

.field-content h3 {
  font-size: 1.75rem !important;
}
.field-content h2 {
  font-size: 2rem !important;
}

.field-content h1 {
  font-size: 2.25rem !important;
}

.landing {
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 0%;
  background-attachment: fixed;
}
.landing .select,
.landing .select select {
  width: 100% !important;
}
.no-event-fount {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
</style>
